import moment from "moment";
import t, { saveLocale } from "./strings";

import cookie from "react-cookies";
import config from "../config.json";
import ReactGA from "react-ga";

ReactGA.initialize(config.ga_key);

const LOCALE_C_KEY = "lawstudio__selected_locale";

const sortedArrayFromObject = (object, sortKey, asc) => {
  let array = [];
  for (let key in object) {
    let item = object[key];
    // zaur
    // item.id = key
    array.push(item);
  }
  array.sort((a, b) => {
    if (a[sortKey] > b[sortKey]) return asc ? 1 : -1;
    if (a[sortKey] < b[sortKey]) return asc ? -1 : 1;
    return 0;
  });
  return array;
};

const printDate = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

const idFromName = (name) => {
  return name.replace(/\s/g, "");
};

const currentLanguage = () => {
  return languageFromLocale(currentLocale()).code
}

const currentLocale = () => {
  let locale = cookie.load(LOCALE_C_KEY)
  if(!locale) {
    locale = config.default_locale
  }
  return locale
}

const languageFromLocale = (locale) => {
  switch (locale) {
    case "fr_FR":
      return { long: "Français", code: "fr" };
    case "fr_CM":
      return { long: "Français", code: "fr" };
    case "en_CM":
      return { long: "English", code: "en" };
    case "en_GH":
      return { long: "English", code: "en" };
    case "pl_PL":
      return { long: "Polski", code: "pl" };
    case "fr_BE":
      return { long: "Français", code: "fr" };
    case "nl_BE":
      return { long: "Nederlands", code: "nl" };
    default:
      return { long: "", code: "" };
  }
};

const userDataFields = [
  {
    label: "First name",
    type: "string",
    handle: "name_first",
  },
  {
    label: "Last name",
    type: "string",
    handle: "name_last",
  },
  {
    label: "Street name",
    type: "string",
    handle: "address_street",
  },
  {
    label: "Street number",
    type: "string",
    handle: "address_number",
  },
  {
    label: "City",
    type: "string",
    handle: "address_city",
  },
  {
    label: "Zip code",
    type: "string",
    handle: "address_zip",
  },
  {
    label: "VAT number",
    type: "string",
    handle: "vat",
  },
  {
    label: "Email",
    type: "string",
    handle: "email",
  },
  {
    label: "Phone number",
    type: "string",
    handle: "phone",
  }, {
    label: 'Company name',
    type: 'string',
    handle: 'company_name'
  }, {
    label: 'Company Type',
    type: 'string',
    handle: 'company_type'
  }, {
    label: 'Company website',
    type: 'string',
    handle: 'company_website'
  }
];

const companyTypeOptions = {
  be: [
    'srl',
    'sprl',
    'sa',
    'sc',
    'asbl',
    'snc',
    'scs',
    'sca'
  ],
  fr: [
    'sarl',
    'sas',
    'sa',
    'sc',
    'snc',
    'sca',
    'scs',
    'eirl',
    'es'
  ]
}

export {
  sortedArrayFromObject,
  printDate,
  idFromName,
  t,
  languageFromLocale,
  saveLocale,
  LOCALE_C_KEY,
  currentLanguage,
  currentLocale,
  ReactGA,
  userDataFields,
  companyTypeOptions
};
