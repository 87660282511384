import React, { useState, useEffect } from "react";

import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";

import './styles/button.scss'
import './styles/cookies.scss'
import './styles/modal.scss'
import './styles/switch.scss'

import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import Collapse from "@material-ui/core/Collapse";
import MuiAlert from "@material-ui/lab/Alert";
import cookie from 'react-cookies';

import { Helmet } from "react-helmet";

import { Router } from "react-router-dom";
import history from "./services/history";
import Routes, {
  isPathPrivate,
  isPathOnlyUnauthorized,
  defaultAuthorizedPath,
  defaultUnauthorizedPath,
} from "./routes";

import {
  createUserStateChangedListener,
  isUserSignedIn,
} from "./services/auth";

import { NavigatorPage } from "./modules";

import * as database from "./services/database";
import * as functions from "./services/functions";

import { t, saveLocale, languageFromLocale, currentLanguage, currentLocale } from "./utils";

import config from "./config.json";

import interactions from "./utils/interactions.json";

import moment from 'moment'
import firebase, { auth } from "firebase";
import Cookies from "./components/Cookies";

const webflow = window.Webflow.require("ix2");

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3032C1",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FF6500",
    },
  },
  typography: {
    fontFamily: ["Sourcesanspro, sans-serif"].join(","),
  },
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
}));

function App() {
  const [snackbar, setSnackbar] = useState({
    open: false,
    text: "",
    color: "info",
  });

  const [loading, setLoading] = useState({
    authStatus: { isLoading: true },
  });

  const [signedIn, setSignedIn] = useState(false);

  const [publicListeners, setPublicListeners] = useState({});

  const [documents, setDocuments] = useState({});

  const [categories, setCategories] = useState({});

  const [user, setUser] = useState({});
  const [userDocuments, setUserDocuments] = useState({});
  const [userFolders, setUserFolders] = useState({});
  const [userDrafts, setUserDrafts] = useState({});
  const [selectedLocale, setSelectedLocale] = useState(config.default_locale);
  const [hasPaid, setHasPaid] = useState(false);
  const [userType, setUserType] = useState(config.multiple_user_types ? 'standard' : '')

  const [cookiesChanged, setCookiesChanged] = useState(false)

  const LS_COOKIE = 'lawstudio_cookie_consent'
  
  const snackbarDuration = 3000;

  // Save cookie
  const acceptCookies = (obj) => {
    const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 730) // expire in 2 years
    cookie.save(
      LS_COOKIE, 
      JSON.stringify(obj), 
      { 
        path: '/', 
        expires: expires
      }
    );
  }

  // Check if cookie exist
  const isCookieAccepted = () => {
    return cookie.load(LS_COOKIE);
  }

  const [showCookies, setShowCookies] = useState(!isCookieAccepted()) 

  // On cookies update
  useEffect(() => {
    if(cookiesChanged) {
      setShowCookies(false)
    }
  }, [cookiesChanged])

  // Show tracking code if cookie tracking prop is set to true
  useEffect(() => {
    if(isCookieAccepted()?.tracking) {
      // setShowTrackingCode(true)
    }
  }, [cookiesChanged])

  const refreshInteractions = () => {
    if (!!webflow) {
      webflow.init(interactions);
    }
  };

  const setLocale = (locale) => {
    // if (config.language_url) {
    //   history.replace(`/${locale}`);
      // return
    // }
    saveLocale(locale);
    loadPublicData();
    setSelectedLocale(locale);
  };

  const getCategories = async (uType) => {
    startLoading("categories");
    let result = await database.getCategories(uType ? uType : userType);
    setCategories(result);
    stopLoading("categories");
  };

  const getDocuments = async () => {
    startLoading("documents");
    let result = await database.getDocuments();
    setDocuments(result);
    stopLoading("documents");
  };

  const loadPublicData = (uType) => {
    if(config.requires_auth && !auth().currentUser) {
      return
    }
    setCategories({});
    setDocuments({});
    getCategories(uType);
    getDocuments();
  };

  const getUser = async () => {
    startLoading("user");
    let data = await database.getUser();
    if (!data.error) {
      setUser(data);
    }
    stopLoading("user");
    return data;
  };

  const updateUserType = async () => {
    setCategories({});
    setDocuments({});
    let uType
    if(config.multiple_user_types && firebase.auth().currentUser) {
      let token = await firebase.auth().currentUser.getIdTokenResult(true)
      if(!token.claims || !token.claims.user_type) {
        return
      }
      uType = token.claims.user_type
      setUserType(uType)
      loadPublicData(uType);
    }
  }

  const onSignUserDocument = async (doc, secondMember) => {
    const { id, name } = doc;
    startLoading("signDocument");
    const document = userDocuments[id];
    const payload = {
      url: document.files["pdf"].url,
      name: name + ".pdf",
      secondMember,
      documentId: id,
    };
    try {
      const result = await functions.requestSignature(payload);
  
      var documentUpdates = {};
      documentUpdates["files.pdf.signStatus"] = "requested";
      documentUpdates["files.pdf.signMemberId"] = result.members[0].id;
      documentUpdates["files.pdf.signFileId"] = result.files[0].id;
  
      await database.updateUserDocument(documentUpdates, id);
      await getUserDocuments();
  
      stopLoading("signDocument");
    } catch (err) {
      stopLoading("signDocument");
      console.log(err)
    }
  };

  const getUserDocuments = async (showLoading = true) => {
    if (showLoading) {
      startLoading("userDocuments");
    }

    let data = await database.getUserDocuments();
    if (!data.error) {
      setUserDocuments(data);
    }
    stopLoading("userDocuments");
  };

  const getUserDrafts = async (showLoading = true) => {
    if (showLoading) {
      startLoading("userDrafts");
    }
    let data = await database.getUserDrafts();
    if (!data.error) {
      setUserDrafts(data);
    }
    stopLoading("userDrafts");
  };

  const getUserFolders = async (showLoading = true) => {
    if (showLoading) {
      startLoading("userFolders");
    }
    let data = await database.getUserFolders();
    if (!data.error) {
      setUserFolders(data);
    }
    stopLoading("userFolders");
  };

  const loadPrivateData = () => {
    getUser();
    getUserDocuments();
    getUserDrafts();
    getUserFolders();
    checkHasPaid();
  };

  const clearPrivateData = () => {
    setUser({});
    setUserDocuments({});
    setUserFolders({});
    setUserDrafts({});
    setHasPaid(false);
  };

  useEffect(() => {
    let ls = publicListeners;

    if (config.language_url) {
      let path = history.location.pathname;
      let components = path.split(["/"]);
      let localeComponent = components[1];
      for (let i in config.locales) {
        if (config.locales[i] === localeComponent) {
          setLocale(config.locales[i]);
          break;
        }
      }
    }

    if (!ls.user) {
      let listener = createUserStateChangedListener(async (user) => {
        setSignedIn(!!user);

        const { pathname } = history.location;
        
        if (!!user) {
          if(config.multiple_user_types) {
            updateUserType()
          } else {
            loadPublicData();
          }
          loadPrivateData();
          if (isPathOnlyUnauthorized(pathname)) {
            history.push(defaultAuthorizedPath);
          }
        } else {
          clearPrivateData();
          loadPublicData();

          if (isPathPrivate(pathname)) {
            history.push(defaultUnauthorizedPath);
          }
        }
        stopLoading("authStatus");
      });
      ls.user = listener;
    }
    setPublicListeners(ls);

    history.listen((location) => {
      const { pathname } = location;
      if (isUserSignedIn() && isPathOnlyUnauthorized(pathname)) {
        history.push(defaultAuthorizedPath);
        return;
      } else if (!isUserSignedIn() && isPathPrivate(pathname)) {
        history.push(defaultUnauthorizedPath);
        return;
      }
    });
  }, []);

  const showSnackbar = ({ text, color }) => {
    let now = moment().valueOf()
    setSnackbar({ open: true, text, color, openAt: now });
  };

  const hideSnackbar = () => {
    let now = moment().valueOf()
    if(now >= snackbar.openAt + snackbarDuration - 500) { // half a second buffer for inaccuracies {}
      setSnackbar({ ...snackbar, open: false });
    }
  };

  const startLoading = (key, message) => {
    let l = loading;
    l[key] = { isLoading: true, message: message };

    setLoading({ ...l });
  };

  const stopLoading = (key) => {
    let l = loading;
    l[key] = { isLoading: false };
    setLoading({ ...l });
  };

  const isLoading = () => {
    for (let key in loading) {
      if (Boolean(loading[key].isLoading)) {
        return key;
      }
    }
    return null;
  };

  const [generatingDocument, setGeneratingDocument] = useState(null);
  const [
    generatingDocumentCancelled,
    setGeneratingDocumentCancelled,
  ] = useState(false);

  const classes = useStyles();

  const downloadDocument = (url) => () => {
    window.open(url, "_blank");
  };

  const cancelDocumentGenerationListener = () => {
    clearInterval(generatingDocument.listener);
    setGeneratingDocumentCancelled(true);
    setGeneratingDocument(null);
  };

  const shouldShowLoading = () => {
    let path = history.location.pathname;
    return path !== "/" && path !== "/fr" && path !== "/nl";
  };

  const isPack = (categoryId) => !!categories[categoryId]?.requirePayment;

  const renderPackLabel = (className) => {
    return <div className={`d-payment-label pack ${className}`}>Pack</div>;
  };

  const renderFreeLabel = (className) => {
    return <div className={`d-payment-label free ${className}`}>Free</div>;
  };

  const renderPaymentLabel = (categoryId) => {
    if (config.requires_payment && !hasPaid) {
      return isPack(categoryId) ? renderPackLabel() : renderFreeLabel();
    }
  };

  const shouldPushToSubscription = (categoryId) =>
    config.requires_payment && !hasPaid && isPack(categoryId);

  const checkHasPaid = async (subscriptionId) => {
    const payload = {
      subscriptionId,
    };
    const result = await functions.checkHasPaid(payload);
    setHasPaid(!!result.checked);
  };

  return (
    <div className="sec-wrap">
      <Helmet>
        <title>{config.name}</title>
        <meta name="description" content={config.description} />
        <link
          href="/images/webclip.png"
          rel="shortcut icon"
          type="image/x-icon"
        />
        <link href="/images/webclip.png" rel="apple-touch-icon" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <NavigatorPage
            history={history}
            setLocale={setLocale}
            showSnackbar={showSnackbar}
            startLoading={startLoading}
            stopLoading={stopLoading}
            signedIn={signedIn}
            refreshInteractions={refreshInteractions}
          >
            <Routes
              history={history}
              categories={categories}
              documents={documents}
              user={user}
              hasPaid={hasPaid}
              userDrafts={userDrafts}
              userDocuments={userDocuments}
              userFolders={userFolders}
              onSignUserDocument={onSignUserDocument}
              getUser={getUser}
              updateUserType={updateUserType}
              getUserDocuments={getUserDocuments}
              getUserDrafts={getUserDrafts}
              getUserFolders={getUserFolders}
              showSnackbar={showSnackbar}
              startLoading={startLoading}
              stopLoading={stopLoading}
              setGeneratingDocument={setGeneratingDocument}
              setGeneratingDocumentCancelled={setGeneratingDocumentCancelled}
              refreshInteractions={refreshInteractions}
              setUser={setUser}
              isPack={isPack}
              renderPaymentLabel={renderPaymentLabel}
              renderPackLabel={renderPackLabel}
              renderFreeLabel={renderFreeLabel}
              shouldPushToSubscription={shouldPushToSubscription}
              checkHasPaid={checkHasPaid}
              setLocale={setLocale}
            />
          </NavigatorPage>
        </Router>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={snackbarDuration}
          onClose={hideSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={hideSnackbar} color={snackbar.color}>
            {snackbar.text}
          </Alert>
        </Snackbar>
        <Snackbar
          open={!!generatingDocument && !generatingDocumentCancelled}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          {!!generatingDocument && !config.static_document_creation_popup ? (
            <div className="alert-loading" style={{ padding: 10 }}>
              <div className="div-block-2">
                <img src="/images/doc_render2x.svg" alt="" />
              </div>
              <div className="div-block-3 fill-width">
                <img
                  onClick={cancelDocumentGenerationListener}
                  src="/images/close_alert.svg"
                  alt="close-icon"
                  className="close-img doc-snackbar-close"
                />
                <div className="text-block-2 fill-width">
                  <strong className="doc-title-loading">
                    {generatingDocument.name}
                  </strong>
                </div>
                <Collapse
                  in={
                    generatingDocument.files.docx.generation_progress === 1 ||
                    generatingDocument.files.pdf.generation_progress === 1
                  }
                >
                  <div className="div-block-4 fill-width pr-2">
                    {generatingDocument.files.docx.generation_progress === 1 ? (
                      <div className="div-loading">
                        <div
                          onClick={downloadDocument(
                            generatingDocument.files.docx.url
                          )}
                          className="c-btn btn--red btn-loading w-inline-block"
                        >
                          <div>{t("mydocs.download", { format: ".docx" })}</div>
                        </div>
                      </div>
                    ) : null}
                    {generatingDocument.files.pdf.generation_progress === 1 ? (
                      <div className="div-loading">
                        <div
                          onClick={downloadDocument(
                            generatingDocument.files.pdf.url
                          )}
                          className="c-btn btn--red btn-loading w-inline-block"
                        >
                          <div>{t("mydocs.download", { format: ".pdf" })}</div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Collapse>
                <Collapse
                  in={generatingDocument.files.docx.generation_progress < 1}
                >
                  <div className="div-loading fill-width pr-2">
                    <div className="doc-percentage">{`${t("mydocs.preparing", {
                      format: ".docx",
                    })} (${(
                      generatingDocument.files.docx.generation_progress * 100
                    ).toFixed(0)}%)`}</div>
                    <LinearProgress
                      variant="determinate"
                      value={
                        generatingDocument.files.docx.generation_progress * 100
                      }
                      color="primary"
                    />
                  </div>
                </Collapse>
                <Collapse
                  in={generatingDocument.files.pdf.generation_progress < 1}
                >
                  <div className="div-loading fill-width pr-2">
                    <div className="doc-percentage">{`${t("mydocs.preparing", {
                      format: ".pdf",
                    })} (${(
                      generatingDocument.files.pdf.generation_progress * 100
                    ).toFixed(0)}%)`}</div>
                    <LinearProgress
                      variant="determinate"
                      value={
                        generatingDocument.files.pdf.generation_progress * 100
                      }
                      color="primary"
                    />
                  </div>
                </Collapse>
              </div>
            </div>
          ) : !!generatingDocument ? (
            <div className="alert-loading" style={{ padding: 10 }}>
              <div className="div-block-2">
                <img src="/images/doc_render2x.svg" alt="" />
              </div>
              <div className="div-block-3 fill-width">
                <img
                  onClick={cancelDocumentGenerationListener}
                  src="/images/close_alert.svg"
                  alt="close-icon"
                  className="close-img doc-snackbar-close"
                />
                <div
                  className="text-block-2 fill-width"
                  style={{ maxWidth: 600 }}
                >
                  <strong className="doc-title-loading">
                    {t("document.creation_popup")}
                  </strong>
                </div>
              </div>
            </div>
          ) : null}
        </Snackbar>
        <Backdrop
          className={classes.backdrop}
          open={Boolean(isLoading()) && shouldShowLoading()}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Fade in={!!loading[isLoading()]}> */}
            <span style={{ marginBottom: 20 }}>
              {!!loading[isLoading()] ? loading[isLoading()].message : ""}
            </span>
            {/* </Fade> */}
            <CircularProgress color="secondary" />
          </div>
        </Backdrop>
      </ThemeProvider>
      <div
        onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
        className={`cta-icon-wrap w-inline-block cta-icon-wrap--${config.class_handle}`}
      >
        <img
          src="/images/arrow_up.svg"
          alt="up-arrow-icon"
          height="16"
          width="16"
          className={`cta-icon arrow-img arrow-img--${config.class_handle}`}
        />
      </div>
      {showCookies && <Cookies shouldAcceptCookies={acceptCookies} setCookiesChanged={setCookiesChanged} />}
    </div>
  );
}

export default App;
