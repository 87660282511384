import firebase from "./firebase";
import config from "../config.json";

// firebase.functions().useFunctionsEmulator('http://localhost:5000')

async function requestSign(url, name) {
  let requestSignFunction = firebase.functions().httpsCallable("requestSign");

  try {
    let result = await requestSignFunction({ url, name });
    return result.data;
  } catch (err) {
    console.log(err);

    return { error: err };
  }
}

async function createDocument(document, answers) {
  let createFunction = firebase.functions().httpsCallable(`createDocument${config.uses_v2 ? '_v2' : ''}`);
  try {
    let result = await createFunction({
      template_id: document.id,
      answers,
      name: document.name,
      environment: config.env,
    });
    return result.data;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

async function createPreview(document, answers) {
  console.log("create preview with answers")
  console.log(answers)
  let createFunction = firebase.functions().httpsCallable(`createPreview${config.uses_v2 ? '_v2' : ''}`);
  try {
    let result = await createFunction({
      documentId: document.id,
      answers,
      environment: config.env,
    });
    return result.data;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

async function sendPasswordResetEmail(email, language) {
  let sendEmailFunction = firebase
    .functions()
    .httpsCallable("sendPasswordResetEmail");
  try {
    let result = await sendEmailFunction({
      email,
      language,
      partner: config.partner,
      app_name: config.name,
      app_suffix: config.copy_suffix || '',
      locales: config.locales
    });
    return result;
  } catch (err) {
    return { error: err };
  }
  // let { email, language, partner, app_name } = body
}

async function requestSignature(data) {
  let requestSignFunction = firebase.functions().httpsCallable("requestSignature");

  try {
    let result = await requestSignFunction(data);
    return result.data;
  } catch (err) {
    console.log(err);

    return { error: err };
  }
}

async function checkVerificationCode(data) {
  let checkVerificationCodeFunc = firebase.functions().httpsCallable("checkVerificationCode");

  try {
    let result = await checkVerificationCodeFunc(data);
    return result.data;
  } catch (err) {
    console.log(err);

    return { error: err };
  }
}

async function assignUserTypeWithCode(code) {
  const assignFunction = firebase.functions().httpsCallable("assignUserTypeWithCode");

  try {
    let result = await assignFunction({ code });
    return result.data;
  } catch (err) {
    console.log(err);

    return { error: err };
  } 
}

async function payment(data) {
  data.environment = config.env
  let paymentFunction = firebase.functions().httpsCallable("payment");

  try {
    let result = await paymentFunction(data);
    return result.data;
  } catch (err) {
    console.log(err);

    return { error: err };
  }
}

async function confirmPayment(data) {
  data.environment = config.env
  let confirmPaymentFunction = firebase.functions().httpsCallable("confirmPayment");

  try {
    let result = await confirmPaymentFunction(data);
    return result.data;
  } catch (err) {
    console.log(err);

    return { error: err };
  }
}

async function checkHasPaid(data) {
  data.environment = config.env
  let checkHasPaidFunction = firebase.functions().httpsCallable("checkHasPaid");

  try {
    let result = await checkHasPaidFunction(data);
    return result.data;
  } catch (err) {
    console.log(err);

    return { error: err };
  }
}

export {
  createDocument,
  createPreview,
  sendPasswordResetEmail,
  requestSign,
  requestSignature,
  payment,
  confirmPayment,
  checkVerificationCode,
  checkHasPaid,
  assignUserTypeWithCode
};
