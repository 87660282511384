import React, { useEffect, useState } from 'react'

import { t, currentLanguage } from '../utils'

import config from '../config.json'

const RequestSecondSignatureForm = (props) => {
    const {
        startLoading,
        stopLoading,
        showSnackbar,
        closeSecondSignatureForm,
        RequestSign
    } = props
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [nameFirst, setNameFirst] = useState('')
    const [nameLast, setNameLast] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
  
    const submit = async () => {
        let incompleteMessage
        if (isEmpty(nameFirst)) {
            incompleteMessage = t('contact.empty_name_first')
        } else if (isEmpty(nameLast)) {
            incompleteMessage = t('contact.empty_name_last')
        } else if (isEmpty(phone)) {
            incompleteMessage = t('contact.empty_phone')
        } else if (isEmpty(email)) {
            incompleteMessage = t('contact.empty_email')
        }
        if (!!incompleteMessage) {
            showSnackbar({ text: incompleteMessage, color: 'warning' })
            return
        }
        startLoading('sendingContact', t('contact.sending'))
        closeSecondSignatureForm()
        const payload = {
            firstname: nameFirst,
            lastname: nameLast,
            email,
            phone
        }
        RequestSign(payload)
        stopLoading('sendingContact')
    }

    const isEmpty = (value) => {
        return !value || value === ''
    }

    return (
        <div className="sec">
            <div className="container">
                <div className="form-card">
                    <div className="form__cont">
                        <div className="pop__title-wrap">
                            <h1 className="pop__h1">{t('requestSecondSignature.title')}</h1>
                        </div>
                        <div className="f__form-wrap">
                            <div className="form w-form">
                                <div id="email-form" name="email-form" data-name="Email Form">
                                    <div className="f__row">
                                        <div className="f__cont-wrap">
                                            <label htmlFor="nameFirst" className="f-form__label">{t('general.name_first')}</label>
                                            <input type="text" value={nameFirst} onChange={e => setNameFirst(e.target.value)} className="f-form__text-field w-input" maxLength="256" name="firstName" data-name="firstName" id="firstName" required="" /></div>
                                        <div className="f__cont-wrap f__cont-wrap--right">
                                            <label htmlFor="nameLast" className="f-form__label">{t('general.name_last')}</label>
                                            <input type="text" value={nameLast} onChange={e => setNameLast(e.target.value)} className="f-form__text-field w-input" maxLength="256" name="lastName" data-name="lastName" id="lastName" required="" /></div>
                                    </div>
                                    <div className="f__row">
                                        <div className="f__cont-wrap">
                                            <label htmlFor="phone" className="f-form__label">{t('contact.phone')}</label>
                                            <input type="text" value={phone} onChange={e => setPhone(e.target.value)} className="f-form__text-field w-input" maxLength="256" name="phone" data-name="phone" id="phone" required="" /></div>
                                        <div className="f__cont-wrap f__cont-wrap--right">
                                            <label htmlFor="email" className="f-form__label">{t('general.email')}</label>
                                            <input type="text" value={email} onChange={e => setEmail(e.target.value)} className="f-form__text-field w-input" maxLength="256" name="email" data-name="email" id="email" required="" /></div>
                                    </div>
                                    <div className="btn-wrap">
                                        <div className="f__btn-line-wrap">
                                            <div onClick={submit} type="submit" className="form__btn btn--full pop-up__btn w-button">{t('requestSecondSignature.request')}</div>
                                            <div className="red-line"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {config.contact_terms ? (
                                <div className="contact-terms">
                                    { t('contact.terms')}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RequestSecondSignatureForm